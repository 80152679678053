<template>
  <main>
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <input
          type="file"
          name=""
          id=""
          class="hidden"
          ref="inputFile"
          accept=".xlsx, .xls, .csv"
          @change="onFileChange"
        />
        <button
          @click="$refs.inputFile.click()"
          class="bg-blue-500 py-4 px-3 rounded-md text-white"
        >
          Upload File
        </button>
        <button
          @click="$router.push({ name: 'AllBulkUploads' })"
          class="bg-blue-500 py-4 px-3 rounded-md text-white  ml-3"
        >
          All Bulk Uploads
        </button>
        <div class="ml-5">
          <select
            class="border-2 rounded-md py-4 pl-3 border-gray-400"
            v-model="bankCodes"
            name=""
            id=""
          >
            <option disabled value=""> Bank Codes</option>
            <option v-for="bank in banks" :key="bank.bank_code" value="">
              {{ bank.bank_code }} -
              {{ bank.name }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <button
          v-if="files && files.length"
          @click="openModal"
          class="bg-blue-500 py-4 px-3 rounded-md text-white"
        >
          Batch Upload
        </button>
      </div>
    </div>

    <div v-if="files && files.length" class="overflow-scroll pb-6 mt-6">
      <table class="shadow-lg bg-white w-full">
        <thead>
          <tr>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Amount
            </th>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Destination Account
            </th>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Destination Bank Code
            </th>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Destination Bank Name
            </th>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Destination Name
            </th>
            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            >
              Narration
            </th>

            <th
              class="
                bg-blue-100
                text-xs
                border
                whitespace-nowrap
                text-left
                px-8
                py-2
              "
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(file, index) in files"
            :key="index"
            :class="index % 2 ? 'bg-gray-300' : ''"
          >
            <!-- <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.merchant_name }}
              </td> -->
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <input
                v-model="files[index].Amount"
                type="number"
                class="py-2 pl-2 w-auto border-2 border-black rounded-md"
                v-if="index === current"
              />
              <p v-else>
                {{ file.Amount }}
              </p>
            </td>
            <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
              <input
                v-model="files[index].DestinationAccount"
                type="text"
                class="py-2 pl-2 w-auto border-2 border-black rounded-md"
                v-if="index === current"
              />
              <p v-else>
                {{ file.DestinationAccount }}
              </p>
            </td>
            <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
              <select
                v-if="index === current"
                class="border-2 rounded-md py-4 pl-3 border-gray-400"
                v-model="files[index].DestinationBankCode"
                name=""
                id=""
              >
                <option disabled value=""> Bank Codes</option>
                <option
                  v-for="bank in banks"
                  :key="bank.bank_code"
                  :value="bank.bank_code"
                >
                  {{ bank.bank_code }} -
                  {{ bank.name }}
                </option>
              </select>

              <p v-else>
                {{ file.DestinationBankCode }}
              </p>
            </td>
            <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
              <p>
                {{ getBankName(file.DestinationBankCode) }}
              </p>
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <input
                v-model="files[index].DestinationName"
                type="text"
                class="py-2 pl-2 w-auto border-2 border-black rounded-md"
                v-if="index === current"
              />
              <p v-else>
                {{ file.DestinationName || "N/A" }}
              </p>
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <input
                v-model="files[index].Narration"
                type="text"
                class="py-2 pl-2 w-auto border-2 border-black rounded-md"
                v-if="index === current"
              />
              <p v-else>
                {{ file.Narration || "N/A" }}
              </p>
            </td>

            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <button
                @click="editValue(index)"
                class="
                  block
                  uppercase
                  shadow
                  bg-green-800
                  hover:bg-blue-700
                  focus:shadow-outline
                  focus:outline-none
                  text-white text-xs
                  py-2
                  px-8
                  rounded
                "
              >
                <p v-if="index === current">Revert</p>
                <p v-else>Edit Value</p>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Modal size="max-w-xl" v-if="modal" @close="modal = false">
      <form @submit.prevent="uploadCsv">
        <div>
          <p>Enter Bulk Upload Name</p>
          <div>
            <input
              type="text"
              class=" border-2 pl-2 py-2 rounded-md mt-5  border-gray-400"
              required
              v-model="name"
            />
            <button
              type="submit"
              class="block mt-12 py-3 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs  px-8 rounded"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </main>
</template>

<script>
import Modal from "../components/Modal.vue";
import readXlsxFile from "read-excel-file";
export default {
  data() {
    return {
      files: [],
      current: null,
      banks: [],
      modal: false,
      name: "",
      bankCodes: "",
    };
  },
  components: {
    Modal,
  },
  methods: {
    editValue(x) {
      if (this.current === x) {
        this.current = null;
        return;
      }
      this.current = x;
    },
    openModal() {
      if (this.current !== null) {
        alert("Make sure all Values are Edited");
        return;
      } else {
        this.modal = true;
      }
    },
    getBankName(code) {
      let k = this.banks.filter((bank) => bank["bank_code"] === code);
      if (k) {
        return k[0].name;
      }
    },
    async uploadCsv() {
      let res = await this.$store.dispatch("FUNDS_TRANSFER", {
        name: this.name,
        BulkRequests: this.files,
      });
      if (res.status) {
        this.modal = false;
        this.name = "";
        this.$router.push({ name: "AllBulkUploads" });
      } else {
        alert("There was an issue Uploading this file");
      }
    },
    async getBanks() {
      let res = await this.$store.dispatch("BANK_LIST");
      this.banks = res.data.items;
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      const schema = {
        Amount: {
          prop: "Amount",
          type: Number,
        },
        DestinationAccount: {
          prop: "DestinationAccount",
          type: String,
        },
        DestinationName: {
          prop: "DestinationName",
          type: String,
        },
        Narration: {
          prop: "Narration",
          type: String,
        },
        DestinationBankCode: {
          prop: "DestinationBankCode",
          type: String,
        },
      };
      let allRow;
      readXlsxFile(xlsxfile, { schema }).then((rows) => {
        allRow = rows.rows;
        let bankCode = this.banks.map((bank) => bank.bank_code);
        let error;
        const found = allRow.every((r, i, t) => {
          let k = bankCode.includes(r.DestinationBankCode);
          if (!k) {
            error = i + 1;
          }
          return k;
        });
        if (found) {
          this.files = allRow;
        } else {
          alert(
            `Error uploading this file due to invalid bank code at line ${error}`
          );
        }
      });
    },
  },
  async mounted() {
    await this.getBanks();
  },
};
</script>

<style></style>
